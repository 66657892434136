var bodyId = $('body').attr('id');
// 自動継続更新のチェックボックスをonにする
var initCardFcAuto = function () {
    if (bodyId !== "fcJoinEntry" && bodyId !== "fcPay") {
        return;
    }
    var $target = $('[id*="card_fc_auto"]');
    $target.prop('checked', true);
};

// 自動継続更新のチェックボックス下注意文言
var initFcAutoAtt = function () {
    if (bodyId !== "fcJoinEntry") {
        return;
    }
    $('.tblpay_param_fc_auto').append('<p class="bold">※自動更新を希望されない方はご自身でチェックを外して下さい</p>');
}